import SlideAfterMotion from '@/components/common/animation/SlideAfterMotion';
import Image from 'next/image';
import React, { useEffect, useRef } from 'react';
import { motion, useInView, useAnimation } from 'framer-motion';
import useBlog from '@/lib/hooks/useBlog';
import { useRouter } from 'next/router';
const BlogSection = () => {
	const { useGetBlog } = useBlog();
	const { data: allBlog, isLoading } = useGetBlog();
	const ref = useRef(null);
	const isInView = useInView(ref, { once: true });
	const mainControls = useAnimation();
	const router = useRouter();

	useEffect(() => {
		if (isInView) {
			mainControls.start('visible');
		}
	}, [isInView]);

	const container = {
		hidden: { opacity: 1, scale: 0 },
		visible: {
			opacity: 1,
			scale: 1,
			transition: {
				delayChildren: 0.4,
				staggerChildren: 0.3,
			},
		},
	};

	const animateItem = {
		hidden: { y: 50, opacity: 0 },
		visible: {
			y: 0,
			opacity: 1,
		},
	};

	return (
		<div className="home_blog_section_wrapper">
			<div className="container">
				<SlideAfterMotion>
					<div className="home_blog_title_wrapper">
						<h1 className="home_blog_title">
							Fastinventory <b className="color_title">Blog</b>
						</h1>
					</div>
				</SlideAfterMotion>

				<motion.div ref={ref} variants={container} initial="hidden" animate={mainControls} className="home_blog_card_item_wrapper">
					{allBlog &&
						allBlog?.results?.map((item) => (
							<motion.div variants={animateItem} className="home_blog_card" key={item?.id}>
								<div className="home_blog_card_image_wrapper">
									<Image alt="image" layout="fill" src={item.thumbnail} />
								</div>
								<div className="home_blog_info">
									<SlideAfterMotion>
										<h5 onClick={() => router.push('/blog/[slug]', `/blog/${item.slug}`)} className="home_blog_info_title">
											{item.title}
										</h5>
									</SlideAfterMotion>
									<SlideAfterMotion>
										<p className="home_blog_info_des">{item.summary}</p>
									</SlideAfterMotion>

									<SlideAfterMotion>
										<div className="read_button_wrapper">
											<p onClick={() => router.push('/blog/[slug]', `/blog/${item.slug}`)} className="read_button">
												Read More ...
											</p>
										</div>
									</SlideAfterMotion>
								</div>
							</motion.div>
						))}
				</motion.div>

				<div className="view_more_button_wrapper">
					<button onClick={() => router.push('/blog')} className="landing_primary_button">
						view more
					</button>
				</div>
			</div>
		</div>
	);
};

export default BlogSection;
