import React from 'react';
import Queries from '@/constants/queries';
import { useQuery } from 'react-query';
import BlogService from '@/services/api/blog.service';

const useBlog = () => {
	const useGetBlog = () => {
		return useQuery({
			queryKey: [Queries.CMS_BLOG],
			queryFn: () => BlogService.getBlog(),
			onSuccess: (data) => {
				return data;
			},
			onError: (error) => {
				console.error('Error fetching blog:', error);
			},
		});
	};

	const useGetFeaturedBlog = () => {
		return useQuery({
			queryKey: [Queries.CMS_FEATURED_BLOG],
			queryFn: () => BlogService.getFeaturedBlog(),
			onSuccess: (data) => {
				return data;
			},
			onError: (error) => {
				console.error('Error fetching blog:', error);
			},
		});
	};
	const useGetRecentBlog = () => {
		return useQuery({
			queryKey: [Queries.CMS_RECENT_BLOG],
			queryFn: () => BlogService.getRecentBlog(),
			onSuccess: (data) => {
				return data;
			},
			onError: (error) => {
				console.error('Error fetching blog:', error);
			},
		});
	};

	return { useGetBlog, useGetFeaturedBlog, useGetRecentBlog };
};

export default useBlog;
