import { apiEndpoint } from '../apiEndpoint';
import { api, authApi } from '../interceptor/auth.interceptor';
import { apiUrl } from '../../config/config';
class BlogService {
  static async getBlog() {
    return await api
      .get(apiUrl + apiEndpoint.cmsBlog.allBlog)
      .then((response) => {
        return response?.data;
      });
  }

  static async getBlogSitemap() {
    return await api
      .get(apiUrl + apiEndpoint.cmsBlog.sitemapDataBlog)
      .then((response) => {
        return response?.data;
      });
  }

  static async getSingleBlog(params) {
    return await api
      .get(apiUrl + apiEndpoint.cmsBlog.allBlog + params.query.slug)
      .then((response) => {
        return response?.data;
      });
  }

  static async getFeaturedBlog() {
    return await api
      .get(apiUrl + apiEndpoint.cmsBlog.featuredBlog)
      .then((response) => {
        return response?.data;
      });
  }

  static async getFeaturesSitemap() {
    return await api
      .get(apiUrl + apiEndpoint.cmsBlog.sitemapDataFeature)
      .then((response) => {
        return response?.data;
      });
  }
  
  static async getRecentBlog() {
    return await api
      .get(apiUrl + apiEndpoint.cmsBlog.recentBlog)
      .then((response) => {
        return response?.data;
      });
  }
}

export default BlogService;
